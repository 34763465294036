<template>
  <div id="app">
    <router-view />
    <vue-particles class="login-bg" color="#fff" :particleOpacity="0.2" :particlesNumber="50" shapeType="circle" :particleSize="5" :lineLinked="false" :moveSpeed="3" :hoverEffect="false">
    </vue-particles>
    <audio id="audio" autoplay loop>
      <source src="./assets/music/a1000_u0_p409_s3274690098.mp3" type="audio/ogg">
    </audio>
  </div>
</template>

<script>
export default {
  mounted() {
    document.addEventListener('click', this.handleUserInteraction);
  },
  methods: {
    handleUserInteraction() {
      // 用户进行了交互，可以在这里播放音频
      let oAudio = document.querySelector("#audio");
      oAudio.play();

      // 移除事件监听器，确保音频只播放一次
      document.removeEventListener('click', this.handleUserInteraction);
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.login-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
  background: linear-gradient(
    rgba(0, 0, 0, 0.6),
    #0e5c74,
    rgba(0, 0, 0, 0.946)
  );
}
@media only screen and (max-width: 400px) {
  .login-bg {
    height: 140%;
  }
}
</style>
