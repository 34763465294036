<template>
  <div class="home">
    <img src="@/assets/bread.png" alt="">
    <div id="text">
      <h1 v-for="(char, index) in text" :key="index" :style="{ '--i': index + 1 }">{{ char }}</h1>
    </div>
    <p>探险未知，征服挑战！"欢迎加入我们的队伍，探索无限可能！无论你是红石大神、建筑大师，还是其他领域的天才，我们都欢迎你的加入。在这里，你将找到一个充满创意和激情的团队，一起打造属于我们的世界！"</p>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data() {
    return {
      text: ['欢', '迎', '来', '到', '面', '包', '人', '官', '网', '!']
    };
  },
  mounted() {
    let dialogTitle = document.querySelector('.live2d-widget-dialog')
    if (dialogTitle) {
      dialogTitle.innerText = '这是我们面包小队的主页哦'
    }
  }
}
</script>

<style scoped>
img {
  animation: floatAnimation 3s infinite;
  width: 15%;
  height: 15%;
  object-fit: contain;
  margin: 11.25rem 0 4.375rem;
}

@media only screen and (max-width: 768px) {
  img {
    width: 50%;
    height: 50%;
    margin: 6rem 0 2vw;
  }
}
@media only screen and (max-width: 400px) {
  img {
    width: 50%;
    height: 50%;
    margin-top: 1rem;
  }
}

@keyframes floatAnimation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-1.875rem);
  }
  100% {
    transform: translateY(0);
  }
}
p,
h2 {
  color: #fff;
}
@keyframes donghua {
  0% {
    transform: translateY(0px);
  }
  20% {
    transform: translateY(-1.25rem);
  }
  40%,
  100% {
    transform: translateY(0px);
  }
}
#text h1 {
  display: inline-block;
  animation: donghua 1.5s ease-in-out infinite;
  animation-delay: calc(0.1s * var(--i));
  color: #fff;
  text-shadow: 0 0 0.625rem rgba(255, 255, 255, 0.5),
    0 0 20px rgba(255, 255, 255, 0.5), 0 0 1.875rem rgba(255, 255, 255, 0.5),
    0 0 40px rgba(14, 56, 70, 0.5), 0 0 4.375rem rgba(14, 56, 70, 0.5),
    0 0 80px rgba(14, 56, 70, 0.5), 0 0 6.25rem rgba(14, 56, 70, 0.5),
    0 0 150px rgba(14, 56, 70, 0.5);
  margin-bottom: 4.375rem;
}
@media only screen and (max-width: 600px) {
  #text h1 {
    margin-top: 5rem;
  }
  p {
    position: fixed;
    bottom: 0;
    text-align: center;
    word-wrap: break-word;
    padding: 0.3rem;
  }
}
@media only screen and (max-width: 400px) {
  #text h1 {
    margin: 0;
    font-size: 1.5rem;
  }
  p {
    font-size: 0.8rem;
  }
  img {
    margin-top: 3rem;
  }
}
@media only screen and (max-width: 350px) {
  img {
    margin-top: 4rem;
  }
}
.home {
  width: 100%;
  text-align: center;
}
</style>