import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LayoutView from '../views/LayoutView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: LayoutView,
    name: 'layout',
    children: [
      {
        path: '/',
        name: 'home',
        component: HomeView,
      },
      {
        path: '/forum',
        name: 'forum',
        component: () => import('@/views/ForumView.vue'),
      },
      {
        path: '/team',
        name: 'team',
        component: () => import('@/views/TeamView.vue'),
      },
      {
        path: '/kook',
        name: 'kook',
        component: () => import('@/views/KookView.vue'),
      },
      {
        path: '/white',
        name: 'white',
        component: () => import('@/views/WhitelistView.vue'),
      },
      {
        path: '/file',
        name: 'file',
        component: () => import('@/views/FileView.vue'),
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
